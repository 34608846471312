<template>
  <div style="text-align: left">
    <!-- <el-button
      class="z-depth-0 redBtn btn-primary"
      @click="showAddPackageModal"
    >Add A Safari Package</el-button> -->

    <article>
      <section>
        <div class="safari_dialogs">
          <el-dialog
            :title="`Add A Safari Package`"
            :visible.sync="isEditPackageDialogVisible"
            :destroy-on-close="true"
            :before-close="handleClose"
            style="text-align: left"
          >
            {{ safariPackage }}
            <div v-loading="isUploadingFile">
              <el-form
                :model="safariPackageForm"
                :rules="packageRules"
                ref="safariPackageForm"
                class="demo-safariPackageForm2"
              >
                <div class="row">
                  <div class="col-md-6">
                    <label>Package Title</label>
                    <el-form-item prop="title">
                      <el-input v-model="safariPackageForm.title"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <label>Amount</label>
                    <el-form-item prop="amount">
                      <el-input v-model="safariPackageForm.amount"></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <label>Country of Destination</label>
                    <el-form-item prop="destination_country">
                      <el-select
                        v-model="safariPackageForm.destination_country"
                        placeholder="Country of Destination"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="country in countries"
                          :key="country.id"
                          :label="country.name"
                          :value="country.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>

                  <div class="col-md-6">
                    <label>Safari Location</label>
                    <el-form-item prop="location">
                      <el-select
                        v-model="safariPackageForm.location"
                        placeholder="Semester"
                        style="width: 100%"
                      >
                        <el-option
                          label="Semester 1"
                          value="1"
                        ></el-option>
                        <el-option
                          label="Semester 2"
                          value="2"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <label>Safari Type</label>
                    <el-form-item prop="safari_type">
                      <el-select
                        v-model="safariPackageForm.safari_type"
                        placeholder="Semester"
                        style="width: 100%"
                      >
                        <el-option
                          label="Semester 1"
                          value="1"
                        ></el-option>
                        <el-option
                          label="Semester 2"
                          value="2"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <label>Package Image</label><br />
                    <el-form-item>
                      <el-upload
                        class="upload-demo"
                        drag
                        action="''"
                        :on-change="handlePreview"
                        :on-remove="handleRemove"
                        :file-list="fileLists"
                        :auto-upload="false"
                        :multiple="false"
                      >

                        <div v-if="!isUploadingFile">
                          <i class="el-icon-upload"></i>
                          <div class="el-upload__text">
                            Drop file here or <em>click to upload</em>
                          </div>
                        </div>
                        <div v-else>
                          <el-progress
                            v-if="imageUploadingProgress != 100"
                            type="circle"
                            :color="progressColors"
                            :percentage="imageUploadingProgress"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <el-progress
                            v-else
                            type="circle"
                            :percentage="100"
                            status="success"
                            :width="80"
                            :stroke-width="4"
                          ></el-progress>
                          <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                            {{
                          imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                        }}
                          </div>
                        </div>
                        <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                      </el-upload>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <label>Package Description</label><br />
                    <el-form-item prop="brief_description">
                      <el-input
                        type="textarea"
                        v-model="safariPackageForm.brief_description"
                        :rows="2"
                        placeholder="Please input"
                      >
                      </el-input>
                    </el-form-item>
                  </div>
                </div>

                <hr />
                <el-form-item class="text-center">
                  <el-button
                    type="primary"
                    @click="editSafariPackage('safariPackageForm')"
                  >Edit Safari Package</el-button>
                </el-form-item>
              </el-form>

              <div class="text-center">
                <div class="text-center">
                  <small>© 2022 Ihamba Adventures All rights reserved.</small>
                </div>
                <div class="text-center">
                  <!--<small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>


<script>
export default {
  data() {
    return {
      addPackageModal: false,
      selectedFile: null,
      isUploadingFile: false,
      value: "",
      loading: false,
      loadingError: false,
      imageUploadingProgress: 0,
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      safariPackageForm: {
        title: "",
        amount: "",
        destination_country: "",
        location: "",
        safari_type: "",
        brief_description: "",
        sub_destination_id: 1,
      },
      fileLists: [],

      countries: [
        {
          id: "1",
          name: "Uganda",
          value: "Uganda",
        },
        {
          id: "2",
          name: "Kenya",
          value: "Kenya",
        },
        {
          id: "3",
          name: "Tanzania",
          value: "Tanzania",
        },
        {
          id: "4",
          name: "Rwanda",
          value: "Rwanda",
        },
        {
          id: "5",
          name: "Burundi",
          value: "Burundi",
        },
        {
          id: "6",
          name: "D.R Congo",
          value: "D.R Congo",
        },
        {
          id: "7",
          name: "South Sudan",
          value: "South Sudan",
        },
      ],

      packageRules: {
        title: [
          {
            required: true,
            message: "Title is required",
            trigger: "blur",
          },
        ],
        amount: [
          {
            required: true,
            message: "Amount is required",
            trigger: "blur",
          },
        ],
        destination_country: [
          {
            required: true,
            message: "destination_country is required",
            trigger: "change",
          },
        ],
        location: [
          {
            required: true,
            message: "location is required",
            trigger: "change",
          },
        ],
        safari_type: [
          {
            required: true,
            message: "safari_type is required",
            trigger: "change",
          },
        ],
        brief_description: [
          {
            required: true,
            message: "brief_description is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  props: {
    isEditPackageDialogVisible: {
      required: true,
      type: Boolean,
    },
    safariPackage: {
      required: true,
      type: Object,
    },
  },

  watch: {
    isEditPackageDialogVisible() {
      if (this.isEditPackageDialogVisible) {
        this.safariPackageForm.title = this.safariPackage.title;
        this.safariPackageForm.amount = this.safariPackage.amount;
        this.safariPackageForm.destination_country =
          this.safariPackage.destination_country;
        this.safariPackageForm.location = this.safariPackage.location;
        this.safariPackageForm.safari_type = this.safariPackage.safari_type;
        this.safariPackageForm.brief_description =
          this.safariPackage.brief_description;
        this.safariPackageForm.sub_destination_id = 1;
        this.selectedFile = this.safariPackage.image;
      }
    },
  },

  methods: {
    handleClose(done) {
      done();
      this.$emit("closeEditPackageDialog");
    },

    handlePreview(file) {
      console.log(file.raw);
      this.fileLists = [file];
      this.selectedFile = file.raw;
    },
    handleRemove() {
      this.selectedFile = null;
    },

    async editSafariPackage(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isUploadingFile = true;
          if (this.selectedFile == null) {
            this.$notify({
              title: "Empty",
              message: "Profile picture is empty",
              type: "warning",
            });
          }

          try {
            this.isUploadingFile = true;
            const formData = new FormData();
            formData.append("title", this.safariPackageForm.title);
            formData.append("image", this.selectedFile);
            formData.append("amount", this.safariPackageForm.amount);
            formData.append(
              "sub_destination_id",
              this.safariPackageForm.sub_destination_id
            );
            formData.append(
              "destination_country",
              this.safariPackageForm.destination_country
            );
            formData.append("location", this.safariPackageForm.location);
            formData.append("safari_type", this.safariPackageForm.safari_type);
            formData.append(
              "brief_description",
              this.safariPackageForm.brief_description
            );
            let request = await this.$http.post(
              `api/safari/package/edit/${this.safariPackage.id}`,
              formData,
              {
                onUploadProgress: (progressEvent) => {
                  this.imageUploadingProgress = +(
                    (progressEvent.loaded / progressEvent.total) *
                    100
                  ).toFixed(0);
                },
              }
            );
            if (
              request.data.success &&
              request.data.message == "safari_tour edited successfully"
            ) {
              this.$emit("refreshPackages");
              this.$notify({
                title: "Success",
                message: "Package edited successfully",
                type: "success",
              });
            } else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            if (error.message == "NetworkError") {
              this.isUploadingFile = false;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred please try again",
                type: "warning",
              });
            }
            this.isUploadingFile = false;
            this.$notify({
              title: "Upload Failed",
              message: "Unable to Upload profile picture now, Please try again",
              type: "error",
            });
          } finally {
            this.isUploadingFile = false;
          }
        } else {
          return false;
        }
      });
    },

    showAddPackageModal() {
      this.addPackageModal = true;
      // this.safariPackageForm.title = "";
      // this.safariPackageForm.year_of_study = "";
      // this.safariPackageForm.semester_of_study = "";
      // this.safariPackageForm.credit_unit = "";
    },
  },
};
</script>

<style scoped>
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search_by_input {
  width: 400px !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.safari_dialogs .el-dialog {
  width: 60%;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .safari_dialogs .el-dialog {
    width: 60%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .safari_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .safari_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .safari_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
